import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,2,4,6,7,8,10];

export const dictionary = {
		"/(pages)/[[lang]]/alle-avtaler": [~12,[2]],
		"/(pages)/[[lang]]/design": [13,[2]],
		"/(pages)/[[lang]]/design/2023": [14,[3]],
		"/(pages)/[[lang]]/design/knapper": [15,[2]],
		"/(pages)/[[lang]]/hoiax": [~16,[2]],
		"/(pages)/[[lang]]/nettbutikk": [~17,[2,4]],
		"/(pages)/[[lang]]/nettbutikk/kategori/[category]": [~20,[2,4]],
		"/(pages)/[[lang]]/nettbutikk/kategori/[category]/kjopsbetingelser": [~21,[2,4]],
		"/(pages)/[[lang]]/nettbutikk/kategori/[category]/standard-installasjon": [~22,[2,4]],
		"/(pages)/[[lang]]/nettbutikk/[product]": [~18,[2]],
		"/(pages)/[[lang]]/nettbutikk/[product]/bestill": [~19,[2]],
		"/(pages)/[[lang]]/om/aktuelt/(lists)": [~23,[2,5]],
		"/(pages)/[[lang]]/om/aktuelt/(lists)/sparetips": [~24,[2,5]],
		"/(pages)/[[lang]]/om/aktuelt/[slug]": [25,[2,6]],
		"/(pages)/[[lang]]/om/personvern": [~26,[2]],
		"/(pages)/[[lang]]/om/personvern/cookies": [27,[2]],
		"/(pages)/[[lang]]/privat/app": [~28,[2]],
		"/(pages)/[[lang]]/privat/avtaler": [~29,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/avtalespot": [~30,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/bonord": [~31,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/borettslag": [~32,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/fastpris": [~33,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/fordelsspot-nord": [~34,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/fordelsspot-sor": [~35,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/ishavsspot": [~36,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/pbl": [~37,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/plusskunde": [~38,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/seniorstrom": [~39,[2,7]],
		"/(pages)/[[lang]]/privat/avtaler/sit": [40,[2,7,8]],
		"/(pages)/[[lang]]/privat/avtaler/strawberry": [~41,[2,7]],
		"/(pages)/[[lang]]/privat/avtalevilkar-for-levering-av-kraft": [~42,[2]],
		"/(pages)/[[lang]]/privat/dagens-strompris": [43,[2,9]],
		"/(pages)/[[lang]]/privat/dagens-strompris/tabell": [44,[2,9]],
		"/(pages)/[[lang]]/privat/dagens-strompris/timespris": [45,[2,9]],
		"/(pages)/[[lang]]/privat/hva-koster": [46,[2,10]],
		"/(pages)/[[lang]]/privat/kundeservice": [~47,[2]],
		"/(pages)/[[lang]]/privat/samarbeidspartnere": [~48,[2]],
		"/(pages)/[[lang]]/privat/skjema": [~49,[2]],
		"/(pages)/[[lang]]/privat/skjema/avslutte-avtale": [50,[2]],
		"/(pages)/[[lang]]/privat/skjema/move-to-new-address": [51,[2]],
		"/(pages)/[[lang]]/privat/smartlading": [~52,[2]],
		"/(pages)/[[lang]]/privat/stromanalyse": [~53,[2]],
		"/(pages)/[[lang]]/sok": [54,[2]],
		"/(pages)/[[lang]]/sporsmal-svar/[...slug]": [~55,[2]],
		"/(pages)/[[lang]]": [~11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';